import React from 'react'

import _ from 'underscore'

import UserActions from '../../actions/UserActions.js'
import LoginActions from '../../actions/LoginActions.js'

import {Address, Popup, Check, Colors, Input, P} from '../UI/index.js'
import {Alert, Button, IconButton, Modal, H4, H6} from '../UI/index.js'
import NewUser from './NewUser.js'

class UserModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            user: NewUser(),
            validation: {},
            loading: false,
            error: '',
            mailLoading: false,
            mailMessage: '',
            mailError: ''
        }

        this.state = structuredClone(this.initialState)
    }

    open(user) {
        const {reseller} = this.props

        user = user ? structuredClone(user) : NewUser()

        _.keys(reseller.permissions).map((permission) => {
            if (!reseller.permissions[permission]) {
                user.permissions[permission] = false
            }
        })

        console.log(user)

        this.setState({
            modalIsOpen: true,
            user
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeValue(key, event) {
        const {user} = this.state

        if (key === 'email') {
            event.target.value = event.target.value.toLowerCase().replace(/\s+/g, '')
        }

        user[key] = event.target.value
        this.setState({user})
    }


    onChangeAddress(address) {
        const {user} = this.state
        user.name = address.name
        user.address = address
        this.setState({user})
    }

    onChangeSettingsValue(key, event) {
        const {user} = this.state
        user.settings[key] = event.target.value
        this.setState({user})
    }

    onChangePermission(key, event) {
        const {user} = this.state
        user.permissions[key] = event.target.checked
        this.setState({user})
    }

    onValidate() {
        const {user} = this.state
        const validation = {}
        let id = ''
        let valid = true


        if (!user.email) {
            id = 'email'
            validation.email = true
            valid = false
        }

        if (!user.name) {
            id = 'name'
            validation.name = true
            valid = false
        }

        if (!valid) {
            const element = document.getElementById(id)
            if (element) {
                element.focus()
            }
        }

        this.setState({validation})
        return valid
    }

    onSendWelcomeMail() {
        const {user} = this.state
        this.setState({mailLoading: true, mailMessage: '', mailError: ''})

        let promise = Promise.resolve()

        if (!user._id) {
            this.setState({loading: true, error: ''})

            promise = new Promise((resolve, reject) => {
                UserActions.create(user, (err) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve()
                    }
                })
            })
        }

        promise.then(() => {
            LoginActions.sendUserWelcomeMail(user, (err, response) => {
                if (err) {
                    this.setState({mailLoading: false, mailError: err})
                } else {
                    if (!user._id) {
                        this.close()
                    } else {
                        this.setState({mailLoading: false, mailMessage: response})
                    }
                }
            })
        }).catch((err) => {
            this.setState({loading: false, error: err, mailLoading: false})
        })
    }

    onReset2FA() {
        const {user} = this.state
        this.popup.open('Reset 2FA methode', 'De gebruiker zal de 2FA methode opnieuw moeten activeren. Inloggen met de huidige methode zal niet meer mogelijk zijn. Weet je zeker dat je door wil gaan?', () => {
            UserActions.reset2FA(user, (err, updatedUser) => {
                if (err) {
                    this.setState({error: err})
                } else {
                    this.setState({user: updatedUser}, () => {
                        this.popup.close()
                    })
                }
            })
        })
    }

    onSubmit(event) {
        event.preventDefault()

        if (this.onValidate()) {
            const {user} = this.state

            if (user._id) {
                UserActions.edit(user, this.response.bind(this))
            } else {
                UserActions.create(user, this.response.bind(this))
            }
        }
    }

    response(err) {
        if (err) {
            this.setState({error: err, loading: false})
        } else {
            this.close()
        }
    }

    render() {
        const {modalIsOpen, user, validation, loading, error, mailLoading, mailMessage, mailError} = this.state
        const {reseller, activeUser} = this.props

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 24}}>
                    <H4>{user._id ? 'Bewerk gebruiker' : 'Nieuwe gebruiker'}</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                <form onSubmit={this.onSubmit.bind(this)}>
                    <Address
                        address={user.address}
                        noTAV
                        onChange={this.onChangeAddress.bind(this)}
                        ref={(ref) => this.address = ref}
                    />


                    <div style={{display: 'flex', marginTop: 24, marginBottom: 24}}>
                        <Input
                            style={{marginRight: 6}}
                            label='Telefoon'
                            value={user.phone}
                            onChange={this.onChangeValue.bind(this, 'phone')}
                        />

                        <Input
                            id='email'
                            style={{marginLeft: 6}}
                            label='Emailadres'
                            value={user.email}
                            isInvalid={validation.email}
                            onChange={this.onChangeValue.bind(this, 'email')}
                        />
                    </div>

                    {(reseller.settings.mfaWebEnabled || reseller.settings.mfaAppEnabled) && activeUser.isOwner && (
                        <div style={{marginBottom: 24}}>
                            <H6>Tweestapsverificatie</H6>
                            <P>Tweestapsverificatie actief: {user.mfaEnabled ? 'Ja' : 'Nee'}</P>
                            {user.mfaEnabled && (
                                <Button
                                    style={{marginTop: 12}}
                                    onClick={this.onReset2FA.bind(this)}
                                    variant='outline-white'
                                >
                                    Reset 2FA
                                </Button>
                            )}
                        </div>
                    )}

                    <H6>Permissies</H6>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                        marginBottom: 10
                    }}
                    >
                        {['orders', 'parcels', 'shifts', 'paychecks', 'subscriptions', 'users', 'customers', 'invoices', 'settings'].map((permission) => {
                            if (reseller.permissions[permission] || permission === 'settings') {
                                return (
                                    <Check
                                        key={permission}
                                        style={{width: '33%'}}
                                        label={labels[permission]}
                                        disabled={!activeUser.permissions[permission]}
                                        checked={user.permissions[permission]}
                                        onChange={this.onChangePermission.bind(this, permission)}
                                    />
                                )
                            }
                        })}
                    </div>

                    <br/>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {mailMessage && <Alert dismissible variant="success">{mailMessage}</Alert>}
                    {mailError && <Alert dismissible variant="danger">{mailError}</Alert>}

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button variant='text' loading={mailLoading} onClick={this.onSendWelcomeMail.bind(this)} >
                            Verstuur welkomstmail
                        </Button>

                        <Button loading={loading} type="submit">
                            Opslaan
                        </Button>
                    </div>
                </form>
                <Popup ref={(ref) => this.popup = ref}/>
            </Modal>
        )
    }
}

const labels =	{
    orders: 'Ritten',
    parcels: 'Zendingen',
    shifts: 'Diensten',
    subscriptions: 'Abonnementen',
    customers: 'Klanten',
    invoices: 'Facturen',
    users: 'Gebruikers',
    paychecks: 'Urenregistratie',
    settings: 'Instellingen'
}

export default (UserModal)
