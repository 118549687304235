// @ts-check

import _ from 'lodash'
import moment from 'moment'
import FileSaver from 'file-saver'
import {stringify} from 'csv-stringify/browser/esm'
import {RRule} from 'rrule'
import dbCodes from '../../server/dbCodes.js'
import languages from '../../server/languages.js'
import convertStatus from '../../server/utils/convertStatus.js'
import Colors from '../components/UI/Colors.js'

/**
 * @deprecated
 */
function ordersToCSV(reseller, orders, customers) {
    const csvContent = [['_id', 'Datum', 'Debiteurnummer', 'Klant', 'Contact', 'Emailadres', 'Telefoon', 'Ophaaladres: Stopnummer', 'Ophaaladres: Naam', 'Ophaaladres: T.a.v.', 'Ophaaladres: Straat', 'Ophaaladres: Nr', 'Ophaaladres: Toevoeging', 'Ophaaladres: 2e adres regel', 'Ophaaladres: Postcode', 'Ophaaladres: Plaats', 'Ophaaladres: Land', 'Ophaaladres: Instructies', 'Ophaaladres: Starttijd', 'Ophaaladres: Eindtijd', 'Ophaaladres: Status', 'Ophaaladres: Opmerking koerier', 'Bezorgadres: Stopnummer', 'Bezorgadres: Naam', 'Bezorgadres: T.a.v.', 'Bezorgadres: Straat', 'Bezorgadres: Nr', 'Bezorgadres: Toevoeging', 'Bezorgadres: 2e adres regel', 'Bezorgadres: Postcode', 'Bezorgadres: Plaats', 'Bezorgadres: Land', 'Bezorgadres: Instructies', 'Bezorgadres: Starttijd', 'Bezorgadres: Eindtijd', 'Bezorgadres: Opties', 'Bezorgadres: Status', 'Bezorgadres: Opmerking koerier', 'Prijs', 'Toeslagen', 'Referentie', 'Track & Trace', 'Track & Trace Link', 'Status', 'Opgehaald om', 'Bezorgd om', 'Bezorgd om (UTC)', 'ETA', 'Route', 'Koerier', 'Opmerkingen', 'Inhoud: Aantal', 'Inhoud: Omschrijving', 'Inhoud: Gewicht (kg)', 'Inhoud: Lengte (cm)', 'Inhoud: Breedte (cm)', 'Inhoud: Hoogte (cm)']]

    if (reseller.settings.orders && reseller.settings.orders.monitorTemperature) {
        csvContent[0].push('Min temperatuur', 'Max temperatuur', 'Laagste temperatuur', 'Hoogste temperatuur', 'Min/Max temperatuur overschreden')
    }

    orders.map((order) => {
        const customer = customers[order.customer]
        const line = []

        const pickupAddress = _.find(order.addresses, (address) => address.type === 'pickup') || {}
        const deliveryAddress = _.find([...order.addresses].reverse(), (address) => address.type === 'delivery') || {}
        deliveryAddress.options = []

        if (deliveryAddress.gpsRequired) {
            deliveryAddress.options.push('GPS verplicht')
        }

        if (deliveryAddress.scanRequired) {
            deliveryAddress.options.push('Scan verplicht')
        }

        if (deliveryAddress.imageRequired) {
            deliveryAddress.options.push('Foto verplicht')
        }

        if (deliveryAddress.signatureRequired) {
            deliveryAddress.options.push('Handtekening')
        }

        if (deliveryAddress.nameRequired && !deliveryAddress.signatureRequired) {
            deliveryAddress.options.push('Naam verplicht')
        }

        if (deliveryAddress.signatureNeighbors) {
            deliveryAddress.options.push('Handtekening buren')
        }

        if (deliveryAddress.statedAddressOnly) {
            deliveryAddress.options.push('Niet bij buren')
        }

        if (deliveryAddress.mailboxDelivery) {
            deliveryAddress.options.push('Brievenbuspakket')
        }

        if (deliveryAddress.ageCheck) {
            deliveryAddress.options.push(`Leeftijdscheck ${deliveryAddress.minimumAge}+`)
        }

        if (deliveryAddress.idCheck) {
            deliveryAddress.options.push('ID check')
        }

        if (deliveryAddress.verificationCode) {
            deliveryAddress.options.push('Verfificatie code')
        }

        let pickupStatus
        let deliveryStatus

        if (pickupAddress) {
            pickupStatus = pickupAddress.pickupStatus || convertStatus.addressToPickupStatus(order, pickupAddress)
        }

        if (deliveryAddress) {
            deliveryStatus = deliveryAddress.deliveryStatus || convertStatus.addressToDeliveryStatus(order, deliveryAddress)
        }

        line.push(order._id)
        line.push(order.date)
        line.push(customer ? customer.debtorCode : '')
        line.push(order.customerAddress?.name || '')
        line.push(order.contact || '')
        line.push(order.email)
        line.push(order.phone)
        line.push(pickupAddress.stopNumber ? pickupAddress.stopNumber : '')
        line.push(pickupAddress.name)
        line.push(pickupAddress.attention)
        line.push(pickupAddress.street)
        line.push(pickupAddress.nr)
        line.push(pickupAddress.addition)
        line.push(pickupAddress.street2)
        line.push(pickupAddress.postalCode)
        line.push(pickupAddress.city)
        line.push(pickupAddress.country)
        line.push(pickupAddress.instructions)
        line.push(pickupAddress.startTime)
        line.push(pickupAddress.endTime)
        line.push(pickupStatus ? dbCodes.pickupStatus[pickupStatus] : '')
        const lastPickupEntryWithComment = [...order.history].reverse().find((obj) => obj.type === 'pickup' && obj.comment)
        if (lastPickupEntryWithComment) {
            line.push(lastPickupEntryWithComment.comment)
        } else {
            line.push(pickupAddress.comment)
        }
        line.push(deliveryAddress.stopNumber ? deliveryAddress.stopNumber : '')
        line.push(deliveryAddress.name)
        line.push(deliveryAddress.attention)
        line.push(deliveryAddress.street)
        line.push(deliveryAddress.nr)
        line.push(deliveryAddress.addition)
        line.push(deliveryAddress.street2)
        line.push(deliveryAddress.postalCode)
        line.push(deliveryAddress.city)
        line.push(deliveryAddress.country)
        line.push(deliveryAddress.instructions)
        line.push(deliveryAddress.startTime)
        line.push(deliveryAddress.endTime)
        line.push(`"${deliveryAddress.options?.join(', ')}"`)
        line.push(deliveryStatus ? dbCodes.deliveryStatus[deliveryStatus] : '')
        const lastDeliveryEntryWithComment = [...order.history].reverse().find((obj) => obj.type === 'delivery' && obj.comment)
        if (lastDeliveryEntryWithComment) {
            line.push(lastDeliveryEntryWithComment.comment)
        } else {
            line.push(deliveryAddress.comment)
        }
        line.push(`€ ${order.price.replace(',', '.')}`)
        if (order.feeIds?.length > 0) {
            const pricetable = reseller.settings.orders.prices.find((pricetable) => pricetable.id === order.priceTableId)

            if (pricetable) {
                const chosenFees = order.feeIds.map((id) => {
                    return pricetable.fees[id]?.name || ''
                })

                line.push(`"${chosenFees.join(' ,')}"`)
            }
        } else {
            line.push(`"${order.fees.join(' ,')}"`)
        }
        line.push(order.reference)
        line.push(order.trackTrace)
        line.push(`https://app.veloyd.nl/track&trace/${order.trackTrace}`)
        line.push(dbCodes.status[order.status])
        line.push(pickupAddress.timeOfArrival || '')
        line.push(deliveryAddress.timeOfArrival || '')
        line.push(deliveryAddress.timeOfArrival ? moment(`${order.date} ${deliveryAddress.timeOfArrival}`).toISOString(true) : '')
        line.push(deliveryAddress.plannedTimeOfArrival || '')
        line.push(order.routeName)
        line.push((order.routeId ? order.messengerName : order.messenger) || '') // order.messenger is DEPRECATED
        line.push(order.notes)
        line.push(order.colli.length || 0)
        line.push(order.colli[0]?.description || '')
        line.push(((order.colli[0]?.weight || 0) /1000).toFixed(2))
        line.push(order.colli[0]?.length || 0)
        line.push(order.colli[0]?.width || 0)
        line.push(order.colli[0]?.height || 0)

        if (order.monitorTemperature) {
            line.push(order.minTemperature)
            line.push(order.maxTemperature)
            line.push(order.lowestTemperature)
            line.push(order.highestTemperature)
            line.push(order.lowestTemperature < order.minTemperature || order.highestTemperature > order.maxTemperature ? 'Ja' : 'Nee')
        }

        csvContent.push(line)
    })

    return csvContent
}

/**
 *
 * @param {Reseller} reseller
 * @param {ExportOrder[]} orders
 * @param {Customer[]} customers
 * @param {OrderExportTemplate} template
 * @returns
 */
function ordersToCSVTemplate(reseller, orders, customers, template) {
    const csvContent = [[]]
    template.columns.forEach((column) => {
        const foundColumn = getColumns(template.method).find((c) => c.value === column)
        const label = `${foundColumn?.typeLabel}: ${foundColumn?.label}`

        csvContent[0].push(label)
    })

    if (template.method === 'order') {
        orders.forEach((order) => {
            const line = []


            template.columns.forEach((column) => {
                const foundColumn = getColumns('order').find((c) => c.value === column)

                if (!foundColumn) {
                    line.push('')
                } else if (foundColumn.get) {
                    let address = order.addresses[0]

                    if (foundColumn.typeLabel === 'Ophaaladres') {
                        address = order.addresses.find((address) => address.type === 'pickup')
                    }

                    if (foundColumn.typeLabel === 'Bezorgadres') {
                        address = order.addresses.find((address) => address.type === 'delivery')
                    }

                    line.push(typeof foundColumn.get === 'function' ? foundColumn.get({reseller, order, customers, address, collo: order.colli[0]}) : foundColumn.get)
                } else {
                    line.push(order[foundColumn.value])
                }
            })

            csvContent.push(line)
        })
    }

    if (template.method === 'address') {
        const addresses = orders.flatMap((order) => {
            return order.addresses.map((address) => ({
                order,
                address
            }))
        })

        addresses.forEach(({address, order}) => {
            const line = []


            template.columns.forEach((column) => {
                const foundColumn = getColumns('address').find((c) => c.value === column)

                if (!foundColumn) {
                    line.push('')
                } else if (foundColumn.get) {
                    let thisAddress = structuredClone(address)

                    const collo = order.colli.find((c) => {
                        if (typeof c.pickupAddressIndex === 'number' && address.type === 'pickup') {
                            return c.pickupAddressIndex === address.index
                        }

                        if (typeof c.deliveryAddressIndex === 'number' && address.type === 'delivery') {
                            return c.deliveryAddressIndex === address.index
                        }
                    }) || {}

                    if (foundColumn.typeLabel === 'Ophaaladres') {
                        thisAddress = address.type === 'pickup' && address
                    }

                    if (foundColumn.typeLabel === 'Bezorgadres') {
                        thisAddress = address.type === 'delivery' && address
                    }


                    // @ts-ignore
                    line.push(typeof foundColumn.get === 'function' ? foundColumn.get({address: thisAddress, order, customers, collo}) : foundColumn.get)
                } else {
                    line.push(order[foundColumn.value])
                }
            })

            csvContent.push(line)
        })
    }

    if (template.method === 'collo') {
        const colli = orders.flatMap((order) => {
            return order.colli.map((collo) => ({
                order,
                collo
            }))
        })

        colli.forEach(({collo, order}) => {
            const line = []

            template.columns.forEach((column) => {
                const foundColumn = getColumns('collo').find((c) => c.value === column)

                if (!foundColumn) {
                    line.push('')
                } else if (foundColumn.get) {
                    let address = {}

                    address = order.addresses.find((address) => address.index === collo?.pickupAddressIndex || address.index === collo?.deliveryAddressIndex) || {}

                    if (foundColumn.typeLabel === 'Ophaaladres') {
                        address = order.addresses.find((address) => address.type === 'pickup' && collo?.pickupAddressIndex === address.index) || {}
                    }

                    if (foundColumn.typeLabel === 'Bezorgadres') {
                        address = order.addresses.find((address) => address.type === 'delivery' && collo?.deliveryAddressIndex === address.index) || {}
                    }

                    // @ts-ignore
                    line.push(typeof foundColumn.get === 'function' ? foundColumn.get({collo, order, address, customers, reseller}) : foundColumn.get)
                } else {
                    line.push(collo[foundColumn.value])
                }
            })

            csvContent.push(line)
        })
    }

    return csvContent
}

function parcelsToCSV(isCustomer, parcels, reseller, customers, carriers) {
    let csvContent = [['_id', 'Datum', 'Debiteurnummer', 'Klant', 'Contactpersoon', 'Naam', 'T.a.v.', 'Straat', 'Nr', 'Toevoeging', '2e adres regel', 'Postcode', 'Plaats', 'Land', 'Emailadres T&T', 'Referentie', 'Bezorginstructie', 'Opties', 'Toeslagen', 'Prijs', 'Productcodes', 'Vervoerder', 'Crediteurnummer', 'Track & Trace', 'Track & Trace Link', 'Bezorgdatum', 'Status', 'Gewicht', 'Lengte', 'Breedte', 'Hoogte', 'Gefactureerd']]

    if (isCustomer) {
        csvContent = [['_id', 'Datum', 'Naam', 'T.a.v.', 'Straat', 'Nr', 'Toevoeging', '2e adres regel', 'Postcode', 'Plaats', 'Land', 'Emailadres T&T', 'Referentie', 'Bezorginstructie', 'Opties', 'Toeslagen', 'Prijs', 'Productcodes', 'Vervoerder', 'Track & Trace', 'Track & Trace Link', 'Bezorgdatum', 'Status', 'Gewicht', 'Lengte', 'Breedte', 'Hoogte']]
    }

    const carrierObject = {}
    carriers.map((carrier) => carrierObject[carrier.name] = carrier)

    parcels.map((parcel, index) => {
        const line = []

        let price = parcel.price

        parcel.fees.map((fee) => {
            if (fee.price) {
                price = (parseFloat(price.replace(',', '.')) + ((parseFloat(fee.price.replace(',', '.'))) * fee.quantity)).toFixed(2).replace('.', ',')
            }
        })

        const fees = parcel.fees.map((fee) => {
            if (fee.quantity === 1) {
                return fee.description
            } else {
                return `${fee.quantity}x ${fee.description}`
            }
        })

        const productCodes = parcel.productCodes.map((item) => {
            if (item.quantity === 1) {
                return item.productCode
            } else {
                return `${item.quantity}x ${item.productCode}`
            }
        })

        parcel.fees.map((fee) => {
            if (fee.productCode) {
                if (fee.quantity === 1) {
                    productCodes.push(fee.productCode)
                } else {
                    productCodes.push(`${fee.quantity}x ${fee.productCode}`)
                }
            }
        })

        const deliveryHistory = _.find(parcel.history, (entry) => entry.status === dbCodes.parcelStatus.bezorgd())
        let deliveryDate = ''

        if (deliveryHistory) {
            deliveryDate = deliveryHistory.timestamp
        }

        line.push(parcel._id)
        line.push(moment(parcel.date).format('DD-MM-YYYY'))
        if (!isCustomer) {
            const customer = customers[parcel.customer]
            line.push(customer ? customer.debtorCode : '')
            line.push(customer ? customer.name : '')
            line.push(parcel.contact)
        }
        line.push(parcel.address.name)
        line.push(parcel.address.attention)
        line.push(parcel.address.street)
        line.push(parcel.address.nr)
        line.push(parcel.address.addition)
        line.push(parcel.address.street2)
        line.push(parcel.address.postalCode)
        line.push(parcel.address.city)
        line.push(parcel.address.country)
        line.push(parcel.emailTT || parcel.address.email || '')
        line.push(parcel.reference)
        line.push(parcel.comment)
        line.push(parcel.options.length > 0 ? `"${parcel.options.join(', ')}"` : '')
        line.push(fees.length > 0 ? `"${fees.join(', ')}"` : '')
        line.push(`"€ ${price}"`)
        line.push(productCodes.length > 0 ? `"${productCodes.join(', ')}"` : '')
        line.push(carrierObject[parcel.carrier]?.displayName || parcel.carrier)

        if (!isCustomer) {
            line.push(reseller.settings.parcels.carriers[parcel.carrier]?.creditorCode || '')
        }

        line.push(parcel.trackTrace)
        line.push(parcel.trackTraceLink)
        line.push(deliveryDate)
        line.push(dbCodes.parcelStatus[parcel.status])
        line.push(parcel.weight)
        line.push(parcel.length || 0)
        line.push(parcel.width || 0)
        line.push(parcel.height || 0)
        if (!isCustomer) {
            line.push(parcel.invoiced ? 'Ja' : 'Nee')
        }


        csvContent.push(line)
    })

    return csvContent
}

function customersToCSV(customers) {
    const csvContent = [['_id', 'Debiteurnummer', 'Naam', 'Straat', 'Huisnummer', 'Toevoeging', '2e adres regel', 'Postcode', 'Plaats', 'Land', 'Telefoon', 'Emailadres', 'Contactpersonen']]

    customers.map((customer, index) => {
        const line = []

        const contacts = customer.contacts.map((contact) => {
            return `${contact.name} ${contact.phone} ${contact.email}`
        }).join(', ')
        line.push(customer._id)
        line.push(customer.debtorCode || '')
        line.push(customer.name)
        line.push(customer.address.street)
        line.push(customer.address.nr)
        line.push(customer.address.addition)
        line.push(customer.address.street2)
        line.push(customer.address.postalCode)
        line.push(customer.address.city)
        line.push(customer.address.country)
        line.push(customer.phone)
        line.push(customer.email)
        line.push(contacts)

        csvContent.push(line)
    })

    return csvContent
}

function subscriptionsToCSV(subscriptions, customers) {
    const csvContent = [['Debiteurnummer', 'Klant', 'Omschrijving', 'Prijs', 'Termijn', 'Eerste factuurdatum', 'Begindatum', 'Einddatum', 'Opmerkingen']]

    subscriptions.map((subscription, index) => {
        const customer = customers[subscription.customer]
        const line = []

        line.push(customer ? customer.debtorCode : '')
        line.push(customer ? customer.name : '')
        line.push(subscription.description)
        line.push(`€ ${subscription.price}`)
        line.push(RRule.fromString(subscription.rrule).toText((t) => {
            return languages.DUTCH.rrule[t] || t
        }, languages.DUTCH))
        line.push(moment(RRule.fromString(subscription.rrule).options.dtstart).format('DD-MM-YYYY'))
        line.push(moment(subscription.startDate).format('DD-MM-YYYY'))
        line.push(subscription.endDate === '2999-12-31' ? '-' : moment(subscription.endDate).format('DD-MM-YYYY'))
        line.push(subscription.notes)

        csvContent.push(line)
    })

    return csvContent
}

function shiftsToCSV(shifts) {
    const csvContent = [['Datum', 'Naam', 'Aanvangstijd', 'Eindtijd', 'Koerier', 'Opmerkingen']]

    shifts.map((shift, index) => {
        const line = []

        line.push(moment(shift.date).format('DD-MM-YYYY'))
        line.push(shift.name)
        line.push(shift.startTime)
        line.push(shift.endTime)
        line.push(shift.messenger)
        line.push(shift.comment)

        csvContent.push(line)
    })

    return csvContent
}

function usersToCSV(users) {
    const csvContent = [['Gebruikersnaam', 'Voornaam', 'Achternaam', 'Emailadres', 'Telefoon']]

    users.map((user, index) => {
        const line = []
        line.push(user.name)
        line.push(user.firstName)
        line.push(user.lastName)
        line.push(user.email)
        line.push(user.phone)

        csvContent.push(line)
    })

    return csvContent
}

function paychecksToCSV(paychecks) {
    const csvContent = [['Naam', 'Dagen', 'Uren', 'Salaris', 'Onbelast']]
    paychecks.map((paycheck, i) => {
        const line = []
        const name = paycheck.firstName && paycheck.lastName ? `${paycheck.lastName}, ${paycheck.firstName}` : paycheck.messenger
        line.push(name)
        line.push(paycheck.days)
        line.push(paycheck.hours)
        line.push(paycheck.salary)
        line.push(paycheck.taxFree)

        csvContent.push(line)
    })

    return csvContent
}

function addressesToCSV(addresses) {
    const csvContent = [['Naam', 'Straat', 'Huisnummer', 'Toevoeging', '2e adres regel', 'Postcode', 'Plaats', 'Land', 'Telefoon', 'Emailadres', 'Instructies']]

    addresses.map((address, index) => {
        const line = []

        line.push(address.name)
        line.push(address.street)
        line.push(address.nr)
        line.push(address.addition)
        line.push(address.street2)
        line.push(address.postalCode)
        line.push(address.city)
        line.push(address.country)
        line.push(address.phone)
        line.push(address.email)
        line.push(address.instructions)

        csvContent.push(line)
    })

    return csvContent
}

function priceTableToCSV(priceTable) {
    const csvContent = []
    const table = []

    _.keys(priceTable.table).map((zone1, i) => {
        _.keys(priceTable.table[zone1]).map((zone2, j) => {
            table[j] = table[j] || []
            table[j+1] = table[j+1] || []

            table[0][i+1] = zone1
            table[j+1][0] = zone2
            table[j+1][i+1] = priceTable.table[zone1][zone2] ? priceTable.table[zone1][zone2].toFixed(2).replace('.', ',') : '0'
        })
    })


    table.map((row) => {
        csvContent.push(row)
    })

    csvContent.push([])
    csvContent.push([])

    _.keys(priceTable.fees).map((name) => {
        csvContent.push(['', '', name, priceTable.fees[name].toFixed(2).replace('.', ',')])
    })

    return csvContent
}

function download(name, content) {
    // utf-8 BOM to display € correctly - https://stackoverflow.com/questions/56154046/downloading-blob-with-type-text-csv-strips-unicode-bom
    const buffer = new ArrayBuffer(3)
    const dataView = new DataView(buffer)
    dataView.setUint8(0, 0xef)
    dataView.setUint8(1, 0xbb)
    dataView.setUint8(2, 0xbf)
    const read = new Uint8Array(buffer)

    stringify(content, {delimiter: ';'}, (err, output) => {
        // @ts-ignore
        const blob = new Blob([read, output], {type: 'data:text/csv,charset=utf-8'})
        FileSaver.saveAs(blob, name)
    })
}


export default {
    orders: (reseller, orders, startDate, endDate, customers, template) => {
        const name = template?.name ? `${template.name}_${startDate}_${endDate}.csv` : `Ritten_${startDate}_${endDate}.csv`
        const content = template ? ordersToCSVTemplate(reseller, orders, customers, template) : ordersToCSV(reseller, orders, customers)
        download(name, content)
    },

    parcels: (isCustomer, parcels, startDate, endDate, reseller, customers, carriers) => {
        const name = `Zendingen_${startDate}_${endDate}.csv`
        const content = parcelsToCSV(isCustomer, parcels, reseller, customers, carriers)
        download(name, content)
    },

    subscriptions: (subscriptions, customers) => {
        const name = 'Abonnementen.csv'
        const content = subscriptionsToCSV(subscriptions, customers)
        download(name, content)
    },

    paychecks: (paychecks, startDate, endDate) => {
        const name = `Urenregistratie_${startDate}_${endDate}.csv`
        const content = paychecksToCSV(paychecks)
        download(name, content)
    },

    shifts: (shifts, startDate, endDate) => {
        const name = `Diensten_${startDate}_${endDate}.csv`
        const content = shiftsToCSV(shifts)
        download(name, content)
    },

    customers: (customers) => {
        const name = 'Klanten.csv'
        const content = customersToCSV(customers)
        download(name, content)
    },

    addresses: (addresses) => {
        const name = 'Adresboek.csv'
        const content = addressesToCSV(addresses)
        download(name, content)
    },

    users: (users) => {
        const name = 'Gebruikers.csv'
        const content = usersToCSV(users)
        download(name, content)
    },

    priceTable: (name, priceTable) => {
        name = `Tarieftabel_${name}.csv`
        const content = priceTableToCSV(priceTable)
        download(name, content)
    }
}


export const getColumns = (type) => {
    /**
     * @type {{[key in 'order' | 'address' | 'collo']: {value: string, label: string, get: (args: {order?: ExportOrder, customers?: Customer[], reseller?: Reseller, address?: OrderAddress, collo?: Collo}) => any}[]}}
     */
    const columns = {
        order: [
            {
                value: 'order._id',
                label: 'Id',
                get: ({order}) => order._id
            },
            {
                value: 'order.date',
                label: 'Datum',
                get: ({order}) => order.date
            },
            {
                value: 'order.customerDebtorCode',
                label: 'Debiteurnummer',
                get: ({order, customers}) => customers[order.customer]?.debtorCode || ''
            },
            {
                value: 'order.customer',
                label: 'Klant',
                get: ({order, customers}) => customers[order.customer]?.name || ''

            },
            {
                value: 'order.sender',
                label: 'Afzender',
                get: ({order}) => order.senderAddress?.name || ''
            },
            {
                value: 'order.contact',
                label: 'Contact',
                get: ({order}) => order?.contact || ''
            },
            {
                value: 'order.email',
                label: 'E-mailadres',
                get: ({order}) => order.email
            },
            {
                value: 'order.phone',
                label: 'Telefoon',
                get: ({order}) => order.phone
            },
            {
                value: 'order.price',
                label: 'Prijs',
                get: ({order}) => `€ ${order?.price?.replace(',', '.')}`
            },
            {
                value: 'order.fees',
                label: 'Toeslagen',
                get: ({order, reseller}) => {
                    if (order?.feeIds?.length > 0) {
                        const pricetable = reseller?.settings.orders.prices.find((pricetable) => pricetable.id === order.priceTableId)

                        if (pricetable) {
                            const chosenFees = order.feeIds.map((id) => {
                                return pricetable.fees[id]?.name || ''
                            })

                            return chosenFees?.length > 0 ? `"${chosenFees.join(' ,')}"` : ''
                        }
                    } else {
                        return order?.fees?.length > 0 ? `"${order.fees.join(' ,')}"` : ''
                    }
                }
            },
            {
                value: 'order.reference',
                label: 'Referentie',
                get: ({order}) => order?.reference || ''
            },
            {
                value: 'order.trackTrace',
                label: 'Track & Trace code',
                get: ({order}) => order?.trackTrace || ''
            },
            {
                value: 'order.trackTraceLink',
                label: 'Track & Trace link',
                get: ({order}) => `https://app.veloyd.nl/track&trace/${order.trackTrace}`
            },
            {
                value: 'order.status',
                label: 'Status',
                get: ({order}) => dbCodes.status[order?.status] || ''
            },
            {
                value: 'order.subStatus',
                label: 'Substatus',
                get: ({order}) => Colors.orderStatusColor(order)?.subStatus || ''
            },
            {
                value: 'order.dateHubScan',
                label: 'Datum aangeleverd',
                get: ({order}) => {
                    let date = ''

                    order.history.forEach((entry) => {
                        if (date) {
                            return
                        }

                        if (entry.colli?.length > 0) {
                            if (entry.colli.some((collo) => collo.status === 'athub')) {
                                date = moment(entry.timestamp).format('YYYY-MM-DD')
                            }
                        }
                    })

                    return date
                }
            },
            {
                value: 'order.completedDate',
                label: 'Datum afgerond',
                get: ({order}) => {
                    let date = ''

                    order.history.forEach((entry) => {
                        if (date) {
                            return
                        }

                        if (entry.status === dbCodes.status.afgerond()) {
                            date = moment(entry.timestamp).format('YYYY-MM-DD')
                        }
                    })

                    return date
                }
            },
            {
                value: 'order.amountOfDaysBetweenHubScanAndCompleted',
                label: 'Aantal dagen tussen aangeleverd en afgerond',
                get: ({order}) => {
                    const cols = getColumns(type)
                    const hubscanDate = cols.find((column) => column.value === 'order.dateHubScan').get({order})
                    const completedDate = cols.find((column) => column.value === 'order.completedDate').get({order})

                    if (hubscanDate && completedDate) {
                        return moment(completedDate).diff(hubscanDate, 'days')
                    }

                    return ''
                }
            },
            {
                value: 'order.nrOfAttempts',
                label: 'Aantal pogingen',
                get: ({order}) => order.history.filter((entry) => entry?.type === 'pickup' || entry?.type === 'delivery').length
            },
            {
                value: 'order.route',
                label: 'Route',
                get: ({order}) => order?.routeName || ''
            },
            {
                value: 'order.vehicleType',
                label: 'Voertuig',
                get: ({order}) => ({
                    bike: 'Fiets',
                    car: 'Auto',
                    '': ''
                }[order?.vehicleType || ''])
            },
            {
                value: 'order.messenger',
                label: 'Koerier',
                get: ({order}) => order?.messengerName || ''
            },
            {
                value: 'order.notes',
                label: 'Opmerkingen',
                get: ({order}) => order?.notes || ''
            },
            {
                value: 'order.nrOfColli',
                label: 'Aantal colli',
                get: ({order}) => order?.colli.length
            },
            {
                value: 'order.pickedUpColli',
                label: 'Aantal colli opgehaald',
                get: ({order}) => order?.colli.filter((collo) => typeof collo.pickupAddressIndex === 'number' && !['cancelled', 'pickup', 'outforpickup', 'notcollected'].includes(collo.status)).length
            },
            {
                value: 'order.deliveredColli',
                label: 'Aantal colli bezorgd',
                get: ({order}) => order?.colli.filter((collo) => typeof collo.pickupAddressIndex === 'number' && collo.status === 'delivered').length
            },
            {
                value: 'order.typeBox',
                label: 'Aantal doos',
                get: ({order}) => order?.colli.filter((collo) => collo.type === 'box').length
            },
            {
                value: 'order.typeCrate',
                label: 'Aantal krat',
                get: ({order}) => order?.colli.filter((collo) => collo.type === 'crate').length
            },
            {
                value: 'order.typeRollContainer',
                label: 'Aantal rolcontainer',
                get: ({order}) => order?.colli.filter((collo) => collo.type === 'roll container').length
            },
            {
                value: 'order.typePallet',
                label: 'Aantal pallet',
                get: ({order}) => order?.colli.filter((collo) => collo.type === 'pallet').length
            },
            {
                value: 'order.typeOther',
                label: 'Aantal overige',
                get: ({order}) => order?.colli.filter((collo) => collo.type === 'other').length
            },
            {
                value: 'order.amountOfAddresses',
                label: 'Aantal adressen',
                get: ({order}) => order?.addresses.length
            }

        ],
        address: [
            {
                value: 'address.type',
                label: 'Type',
                get: ({address}) => dbCodes.addressType[address?.type] || ''
            },
            {
                value: 'address.stopNumber',
                label: 'Stopnummer',
                get: ({address}) => address?.stopNumber ?? ''
            },
            {
                value: 'address.name',
                label: 'Naam',
                get: ({address}) => address?.name || ''
            },
            {
                value: 'address.attention',
                label: 'T.a.v.',
                get: ({address}) => address?.attention || ''
            },
            {
                value: 'address.street',
                label: 'Straat',
                get: ({address}) => address?.street || ''
            },
            {
                value: 'address.nr',
                label: 'Huisnummer',
                get: ({address}) => address?.nr || ''
            },
            {
                value: 'address.addition',
                label: 'Huisnummer toevoeging',
                get: ({address}) => address?.addition || ''
            },
            {
                value: 'address.street2',
                label: '2e adres regel',
                get: ({address}) => address?.street2 || ''
            },
            {
                value: 'address.postalCode',
                label: 'Postcode',
                get: ({address}) => address?.postalCode || ''
            },
            {
                value: 'address.city',
                label: 'Plaats',
                get: ({address}) => address?.city || ''
            },
            {
                value: 'address.country',
                label: 'Land',
                get: ({address}) => address?.country || ''
            },
            {
                value: 'address.instructions',
                label: 'Instructies',
                get: ({address}) => address?.instructions || ''
            },
            {
                value: 'address.startTime',
                label: 'Tijdvak starttijd',
                get: ({address}) => address?.startTime || ''
            },
            {
                value: 'address.endTime',
                label: 'Tijdvak eindtijd',
                get: ({address}) => address?.endTime || ''
            },
            {
                value: 'address.status',
                label: 'Status',
                get: ({address, order}) => {
                    if (!address) {
                        return ''
                    }

                    const status = address[`${address.type}Status`] || convertStatus[`addressTo${address.type === 'delivery' ? 'Delivery' : 'Pickup'}Status`](order, address)

                    return status ? dbCodes[`${address.type}Status`][status] : ''
                }
            },
            {
                value: 'address.signee',
                label: 'Naam ontvanger',
                get: ({address, order}) => {
                    if (!address) {
                        return ''
                    }

                    const reversedHistory = order && [...order.history].reverse()

                    return reversedHistory?.find((entry) => entry.type === address.type && entry.addressIndex === address.index)?.signee || ''
                }
            },
            {
                value: 'address.comment',
                label: 'Opmerking koerier',
                get: ({address, order}) => {
                    if (!address) {
                        return ''
                    }
                    const reversedHistory = [...order?.history || []].reverse()

                    return reversedHistory?.find((entry) => entry.type === address.type && entry.addressIndex === address.index)?.comment || ''
                }
            },
            {
                value: 'address.options',
                label: 'Opties',
                get: ({address}) => {
                    if (!address) {
                        return ''
                    }

                    const options = []

                    if (address.gpsRequired) {
                        options.push('GPS verplicht')
                    }

                    if (address.scanRequired) {
                        options.push('Scan verplicht')
                    }

                    if (address.imageRequired) {
                        options.push('Foto verplicht')
                    }

                    if (address.signatureRequired) {
                        options.push('Handtekening')
                    }

                    if (address.nameRequired && !address.signatureRequired) {
                        options.push('Naam verplicht')
                    }

                    if (address.signatureNeighbors) {
                        options.push('Handtekening buren')
                    }

                    if (address.statedAddressOnly) {
                        options.push('Niet bij buren')
                    }

                    if (address.mailboxDelivery) {
                        options.push('Brievenbuspakket')
                    }

                    if (address.minimumAge) {
                        options.push(`Leeftijdscheck ${address.minimumAge}+`)
                    }

                    if (address.idCheck) {
                        options.push('ID check')
                    }

                    if (address.verificationCode) {
                        options.push('Verfificatie code')
                    }

                    return options?.length > 0 ? `"${options?.join(', ')}"` : ''
                }
            },
            {
                value: 'address.eta',
                label: 'Geplande ETA',
                get: ({address}) => address?.plannedTimeOfArrival || ''
            },
            {
                value: 'address.etaUtc',
                label: 'Geplande ETA (UTC)',
                get: ({address, order}) => address?.plannedTimeOfArrival ? moment(`${order.date} ${address.plannedTimeOfArrival}`).toISOString(true) : ''
            },
            {
                value: 'address.estimatedTimeOfArrival',
                label: 'Laatst berekende ETA',
                get: ({address}) => address?.estimatedTimeOfArrival || ''
            },
            {
                value: 'address.estimatedTimeOfArrivalUtc',
                label: 'Laatst berekende ETA (UTC)',
                get: ({address, order}) => address?.estimatedTimeOfArrival ? moment(`${order.date} ${address.estimatedTimeOfArrival}`).toISOString(true) : ''
            },
            {
                value: 'address.pickupAt',
                label: 'Aankomsttijd',
                get: ({address}) => address.timeOfArrival || ''
            },
            {
                value: 'address.pickupAtUtc',
                label: 'Aankomsttijd (UTC)',
                get: ({address, order}) => address?.timeOfArrival ? moment(`${order.date} ${address.timeOfArrival}`).toISOString(true) : ''
            },
            {
                value: 'address.amountOfTries',
                label: 'Aantal pogingen',
                get: ({address, order}) => {
                    if (!address) {
                        return ''
                    }

                    return order.history.filter((entry) => entry.type === address.type && entry.addressIndex === address.index).length
                }
            }
        ],
        collo: [
            {
                value: 'collo.barcode',
                label: 'Barcode',
                get: ({collo}) => collo.barcode || ''
            },
            {
                value: 'collo.type',
                label: 'Type',
                get: ({collo}) => collo.barcode || ''
            },
            {
                value: 'collo.description',
                label: 'Omschrijving',
                get: ({collo}) => collo.description || ''
            },
            {
                value: 'collo.weight',
                label: 'Gewicht (g)',
                get: ({collo}) => collo.barcode || ''
            },
            {
                value: 'collo.length',
                label: 'Lengte (mm)',
                get: ({collo}) => collo.length || ''
            },
            {
                value: 'collo.width',
                label: 'Breedte (mm)',
                get: ({collo}) => collo.width || ''
            },
            {
                value: 'collo.height',
                label: 'Hoogte (mm)',
                get: ({collo}) => collo.height || ''
            },
            {
                value: 'collo.status',
                label: 'Status',
                get: ({collo}) => dbCodes.colloStatus[collo.status]
            },
            {
                value: 'collo.reason',
                label: 'Status reden',
                get: ({collo}) => Colors.colloStatusColor('', collo).reason // use colloStatusColor to add nrNeighbours and deliveredInMailbox
            },
            {
                value: 'collo.pickupAt',
                label: 'Opgehaald om',
                get: ({collo, order}) => {
                    if (typeof collo.pickupAddressIndex === 'number') {
                        const entry = [...order.history].reverse().find((entry) => entry.type === 'pickup' && entry.addressIndex === collo.pickupAddressIndex)
                        return entry?.timestamp ? moment(entry.timestamp).format('HH:mm') : ''
                    }
                }
            },
            {
                value: 'collo.pickupAtUtc',
                label: 'Opgehaald om (UTC)',
                get: ({collo, order}) => {
                    if (typeof collo.pickupAddressIndex === 'number') {
                        const entry = [...order.history].reverse().find((entry) => entry.type === 'pickup' && entry.addressIndex === collo.pickupAddressIndex)
                        return entry?.timestamp ? moment(entry.timestamp).utc().format() : ''
                    }
                }
            },
            {
                value: 'collo.deliveredAt',
                label: 'Bezorgd om',
                get: ({collo, order}) => {
                    if (typeof collo.deliveryAddressIndex === 'number') {
                        const entry = [...order.history].reverse().find((entry) => entry.type === 'delivery' && entry.addressIndex === collo.deliveryAddressIndex)
                        return entry?.timestamp ? moment(entry.timestamp).format('HH:mm') : ''
                    }
                }
            },
            {
                value: 'collo.deliveredAtUtc',
                label: 'Bezorgd om (UTC)',
                get: ({collo, order}) => {
                    if (typeof collo.deliveryAddressIndex === 'number') {
                        const entry = [...order.history].reverse().find((entry) => entry.type === 'delivery' && entry.addressIndex === collo.deliveryAddressIndex)
                        return entry?.timestamp ? moment(entry.timestamp).utc().format() : ''
                    }
                }
            },
            {
                value: 'collo.firstHubScan',
                label: 'Eerste hubscan',
                get: ({collo, order}) => {
                    const entry = order.history.find((entry) => entry.type === 'scan' && entry.colli?.some((c) => c.barcode === collo.barcode))
                    const time = entry?.timestamp ? moment(entry.timestamp).format('DD-MM-YYYY HH:mm') : ''
                    return time ? `"${order.reseller}, ${time}"` : ''
                }
            },
            {
                value: 'collo.lastHubScan',
                label: 'Laatste hubscan',
                get: ({collo, order}) => {
                    const entry = [...order.history].reverse().find((entry) => entry.type === 'scan' && entry.colli?.some((c) => c.barcode === collo.barcode))
                    const time = entry?.timestamp ? moment(entry.timestamp).format('DD-MM-YYYY HH:mm') : ''
                    return time ? `"${order.reseller}, ${time}"` : ''
                }
            }
        ]
    }

    if (type === 'order') {
        const startInfo = 'Bij export per rit is dit'
        return [
            ...columns.order.map((column) => ({...column, typeLabel: 'Rit', typeColor: Colors.successBright})),
            ...columns.address.map((column) => ({...column, value: `pickup${column.value}`, typeLabel: 'Ophaaladres', typeColor: Colors.pickup, info: `${startInfo} het eerste ophaaladres`})),
            ...columns.address.map((column) => ({...column, value: `delivery${column.value}`, typeLabel: 'Bezorgadres', typeColor: Colors.delivery, info: `${startInfo} het eerste bezorgadres`})),
            // ...columns.address.map((column) => ({...column, typeLabel: 'Eerste adres', typeColor: Colors.pickupDelivery})),
            ...columns.address.map((column) => ({...column, typeLabel: 'Adres', typeColor: Colors.pickupDelivery, info: `${startInfo} het eerste adres`})),
            // ...columns.collo.map((column) => ({...column, typeLabel: 'Eerste collo', typeColor: Colors.warningBright})),
            ...columns.collo.map((column) => ({...column, typeLabel: 'Collo', typeColor: Colors.warningBright, info: `${startInfo} de eerste collo`}))
        ]
    }

    if (type === 'address') {
        const startInfo = 'Bij export per adres is dit'
        return [
            ...columns.order.map((column) => ({...column, typeLabel: 'Rit', typeColor: Colors.successBright})),
            ...columns.address.map((column) => ({...column, value: `pickup${column.value}`, typeLabel: 'Ophaaladres', typeColor: Colors.pickup})),
            ...columns.address.map((column) => ({...column, value: `delivery${column.value}`, typeLabel: 'Bezorgadres', typeColor: Colors.delivery})),
            ...columns.address.map((column) => ({...column, typeLabel: 'Adres', typeColor: Colors.pickupDelivery})),
            // ...columns.collo.map((column) => ({...column, typeLabel: 'Eerste collo', typeColor: Colors.warningBright})),
            ...columns.collo.map((column) => ({...column, typeLabel: 'Collo', typeColor: Colors.warningBright, info: `${startInfo} de eerste bijbehorende collo`}))
        ]
    }

    if (type === 'collo') {
        const startInfo = 'Bij export per collo is dit'
        return [
            ...columns.order.map((column) => ({...column, typeLabel: 'Rit', typeColor: Colors.successBright})),
            ...columns.address.map((column) => ({...column, value: `pickup${column.value}`, typeLabel: 'Ophaaladres', typeColor: Colors.pickup})),
            ...columns.address.map((column) => ({...column, value: `delivery${column.value}`, typeLabel: 'Bezorgadres', typeColor: Colors.delivery})),
            ...columns.address.map((column) => ({...column, typeLabel: 'Adres', typeColor: Colors.pickupDelivery, info: `${startInfo} het eerste bijbehorende adres`})),
            ...columns.collo.map((column) => ({...column, typeLabel: 'Collo', typeColor: Colors.warningBright}))
        ]
    }
}
