import React from 'react'
import Colors from './Colors.js'


export default class Card extends React.Component {
    render() {
        const {style={}, selected, children, shadow} = this.props

        const defaultStyle = {
            background: selected ? Colors.backgroundSelected : Colors.backgroundWhite,
            flex: style.flex || style.width ? '' : 1,
            padding: 6,
            border: shadow ? 'none' : `1px solid ${selected ? Colors.grey40 : Colors.grey40}`,
            borderRadius: 4,
            flexShrink: 0,
            boxShadow: shadow ? '0px 2px 4px 0px rgba(0, 0, 0, 0.25)' : '',
            cursor: this.props.onClick ? 'pointer' : 'default',
            ...style
        }

        return (
            <div {...this.props} style={defaultStyle} >
                {children}
            </div>

        )
    }
}

