import React from 'react'

import {Colors, Info, ListItem, P, Popover} from '../UI/index.js'

class Input extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            focused: false,
            isInvalid: false,
            showPassword: false,
            suggested: 0
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.suggested !== prevState?.suggested) {
            const el = this.popoverContentRef.querySelector(`#suggestion-${this.state.suggested}`)
            if (el) {
                const offsetTop = el.offsetTop
                const parentHeight = this.popoverContentRef.getBoundingClientRect().height

                const parentScrollOffsetTop = offsetTop - this.popoverContentRef.scrollTop

                if ((offsetTop > parentHeight && parentScrollOffsetTop > parentHeight) || (parentScrollOffsetTop < 0)) {
                    this.popoverContentRef.scrollTo({top: offsetTop})
                }
            }
        }
        if (this.props?.suggestions?.toString() !== prevProps?.suggestions?.toString()) {
            this.popoverContentRef.scrollTo({top: 0})
            this.setState({suggested: 0})
        }
    }

    focus() {
        this.input.focus()
    }

    select() {
        this.input.select()
    }

    blur() {
        this.input.blur()
    }

    getRef() {
        return this.input
    }

    setInvalid() {
        this.setState({isInvalid: true})
        this.focus()
    }

    capitalizeText(text) {
        text = text.trimStart()

        // Change the first character to uppercase and the rest to lowercase
        let formatted = text.toLowerCase()
        if (formatted.length > 0) {
            formatted = formatted[0].toUpperCase() + formatted.slice(1)
        }

        return formatted
    }

    onChange(event) {
        const {onChange, min, max, type, regex, toUpperCase, capitalize, suggestions} = this.props
        this.setState({isInvalid: false})

        if (suggestions?.length > 0) {
            const exactMatch = suggestions.findIndex((suggestion) => {
                if (typeof suggestion === 'string') {
                    return suggestion === event.target.value
                }

                return suggestion.value === event.target.value
            })

            if (exactMatch > -1) {
                this.setState({suggested: exactMatch})
            }
        }

        if (type === 'number') {
            event.target.value = parseInt(event.target.value || '0')
            if (parseInt(event.target.value) < min) {
                event.target.value = min
            }

            if (parseInt(event.target.value) > max) {
                event.target.value = max
            }
        }

        if (toUpperCase) {
            event.target.value = event.target.value.toUpperCase()
        }

        if (capitalize) {
            event.target.value = this.capitalizeText(event.target.value)
        }

        if (regex && typeof regex.test === 'function') {
            if (regex.test(event.target.value)) {
                typeof onChange === 'function' && onChange(event)
            }
        } else {
            typeof onChange === 'function' && onChange(event)
        }
    }

    boldMatch(text, query) {
        const parts = text.split(new RegExp(`(${query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi')) // .replace to escape special characters from regex
        return parts.map((part, i) => (
            <span key={i} style={part?.toLowerCase() === query?.toLowerCase() ? {fontWeight: 'bold'} : {}}>
                {part}
            </span>
        ))
    }

    render() {
        const {style={}, inputStyle, id, type='text', required, label, info, infoIcon, value, placeholder, noPlaceholder, autocomplete, suggestions, disabled, readOnly, isInvalid, onFocus, onBlur, prepend, append, inputMode, onKeyDown, onKeyUp, onKeyPress, onChooseSuggestion, suggestionFilter, maxLength} = this.props
        const {focused, showPassword, suggested, isInvalid: isInvalidState} = this.state

        const focusedState = focused || value || noPlaceholder || placeholder || value === 0

        const defaultStyle = {
            flex: 1,
            flexShrink: 0,
            position: 'relative',
            marginLeft: 6,
            marginRight: 6,
            marginBottom: 12,
            height: 42
        }

        if (style && style.width) {
            delete defaultStyle.flex
        }

        const labelStyle = {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            paddingLeft: prepend && !focusedState ? 36 : 10,
            top: focusedState ? 2 : 10,
            fontSize: focusedState ? 12 : 14,
            color: Colors.textMedium,
            cursor: disabled || readOnly ? 'default' : 'text',
            transition: 'all .2s ease'
        }

        const defaultInputStyle = {
            fontSize: 14,
            height: 42,
            paddingTop: 12,
            paddingBottom: focusedState && focused ? 1 : 2,
            paddingLeft: prepend ? 24 : 10,
            paddingRight: append ? 24 : 10,
            width: '100%',
            outline: 'none',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderColor: disabled && !readOnly ? Colors.border : (isInvalid || isInvalidState) ? Colors.errorBright : (focusedState && focused) ? Colors.buttonSolid : Colors.border,
            borderRadius: '4px 4px 0px 0px',
            background: readOnly ? 'transparent' : disabled ? Colors.grey20 :Colors.backgroundWhite,
            color: disabled && !readOnly? Colors.textMedium : Colors.textDark,
            cursor: disabled || readOnly ? 'default' : 'text'
        }

        style && Object.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        inputStyle && Object.keys(inputStyle).map((key) => {
            defaultInputStyle[key] = inputStyle[key]
        })

        const innerContent = (
            <>
                <div style={defaultStyle} >
                    <div style={labelStyle} onClick={() => this.input.focus()}>
                        <div style={{whiteSpace: 'nowrap'}}>{label}</div>
                        {info && <Info icon={infoIcon} text={info}/>}
                    </div>

                    {prepend?.includes('mdi') &&
                    <i
                        style={{position: 'absolute', left: 10, top: 10, fontSize: 20, width: 20, height: 20}}
                        className={prepend}
                    />
                    }

                    {prepend && !prepend.includes('mdi') &&
                        <div
                            style={{
                                position: 'absolute',
                                left: 6,
                                bottom: 6,
                                fontSize: 14,
                                fontWeight: 400,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 20,
                                height: 20
                            }}
                        >
                            {prepend}
                        </div>
                    }

                    <input
                        style={defaultInputStyle}
                        id={id}
                        type={showPassword ? '' : type}
                        placeholder={placeholder}
                        value={value === 0 ? 0 : (value || '')}
                        autoComplete={autocomplete}
                        // list={suggestions && `suggestions${id}`}
                        disabled={disabled || readOnly}
                        required={required}
                        onFocus={() => {
                            if (!disabled && !readOnly) {
                                this.setState({focused: true})
                                onFocus && onFocus()
                                this?.popover?.show()
                            }
                        }}
                        onBlur={() => {
                            this.setState({focused: false})
                            onBlur && onBlur()
                            this?.popover?.close()
                        }}
                        onClick={this.props.onClick && this.props.onClick.bind(this)}
                        onKeyDown={(event) => {
                            onKeyDown && onKeyDown(event)

                            if (this?.popover?.closed()) {
                                this?.popover?.show()
                            }

                            if (event.key === 'ArrowDown' && suggested < suggestions?.length) { // Down
                                // to prevent cursor going to the end/right of the input
                                event.preventDefault()
                                if (suggested < suggestions.length-1) {
                                    this.setState({suggested: suggested === -1 ? 0 : suggested+1})
                                } else {
                                    this.setState({suggested: 0})
                                }
                            }

                            if (event.key === 'ArrowUp' && suggested >= 0 && suggestions) { // Up
                                // to prevent cursor going to the start/left of the input
                                event.preventDefault()
                                if (suggested === 0) {
                                    this.setState({suggested: suggestions.length-1})
                                } else {
                                    this.setState({suggested: suggested-1})
                                }
                            }
                        }}
                        onKeyUp={onKeyUp}
                        onKeyPress={(event) => {
                            onKeyPress && onKeyPress(event)

                            if (event.key === 'Enter' && suggestions?.length) {
                                if (!this?.popover?.closed()) {
                                    event.preventDefault()
                                }
                                this.onChange({target: {value: suggestions?.[suggested]?.value || suggestions?.[suggested]}})
                                onChooseSuggestion && onChooseSuggestion({target: {value: suggestions?.[suggested]?.value || suggestions?.[suggested]}})
                                this?.popover?.close()
                            }
                        }}
                        onChange={this.onChange.bind(this)}
                        ref={(ref) => this.input = ref}
                        inputMode={inputMode}
                        maxLength={maxLength}
                    />

                    {typeof append === 'string' && append?.includes('mdi') &&
                    <i
                        style={{position: 'absolute', right: 10, top: 10, fontSize: 20, width: 20, height: 20}}
                        className={append}
                    />
                    }

                    {type === 'password' &&
                    <i
                        style={{position: 'absolute', right: 10, top: 10, fontSize: 20, width: 20, height: 20, cursor: 'pointer'}}
                        className={showPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}
                        onClick={() => this.setState({showPassword: !showPassword})}
                    />
                    }

                    {typeof append === 'string' && !append.includes('mdi') &&
                    <div
                        style={{
                            position: 'absolute',
                            right: 6,
                            bottom: 6,
                            fontSize: 14,
                            fontWeight: 400,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 20,
                            height: 20,
                            color: Colors.textMedium
                        }}
                    >
                        {append}
                    </div>
                    }

                    {typeof append !== 'string' && append &&
                    <div
                        style={{
                            position: 'absolute',
                            right: 2,
                            bottom: 3
                        }}
                    >
                        {append}
                    </div>
                    }
                </div>
                <Popover
                    noClose
                    ref={(ref) => this.popover = ref}
                    inputRef={this.input}
                    disabled={!suggestions?.length}
                    content={
                        <div style={{maxHeight: 400, overflowY: 'auto'}} ref={(ref) => this.popoverContentRef = ref}>
                            {suggestions && suggestions.length > 0 && suggestions.map((suggestion, index) => {
                                if (typeof suggestion === 'string') {
                                    if (suggestionFilter && !suggestion.match(new RegExp(`${value}`, 'gi'))) {
                                        return
                                    }

                                    return (
                                        <ListItem
                                            key={`${index}select${id}`}
                                            focused={suggested === index}
                                            onMouseDown={() => {
                                                this.onChange({target: {value: suggestion}})
                                                onChooseSuggestion && onChooseSuggestion({target: {value: suggestion}})
                                                this.popover.close()
                                            }}
                                            id={`suggestion-${index}`}
                                        >
                                            <P ellipsis>
                                                {this.boldMatch(suggestion, value)}
                                            </P>
                                        </ListItem>
                                    )
                                }

                                if (suggestionFilter && !suggestion.value.match(new RegExp(`${value}`, 'gi'))) {
                                    return
                                }

                                return (
                                    <ListItem
                                        key={`${index}select${id}`}
                                        focused={suggested === index}
                                        onMouseDown={() => {
                                            this.onChange({target: {value: suggestion.value}})
                                            onChooseSuggestion && onChooseSuggestion({target: {value: suggestion.value}})
                                            this.popover.close()
                                        }}
                                        id={`suggestion-${index}`}
                                    >
                                        <P ellipsis>
                                            {this.boldMatch(suggestion.label || suggestion.value, value)}
                                        </P>
                                    </ListItem>
                                )
                            })}
                        </div>
                    }
                />
            </>
        )
        return innerContent
    }
}


export default Input
