import React from 'react'
import Reflux from 'reflux-react-16'
import _ from 'lodash'

import OrderStore from '../../stores/OrderStore.js'
import OrderActions from '../../actions/OrderActions.js'
import UserStore from '../../stores/UserStore.js'

import {Alert, Panel, Popup, P, Colors, Button, Tabs, Tab, Icon, FlexColumn, FlexRow, ToolTip, S1, H6} from '../../components/UI/index.js'
import OrderList from '../../components/routes/OrderList.js'
import OrderCard from '../../components/orders/OrderCard.js'
import Map from '../../components/map/Map.js'
import dbCodes from '../../../server/dbCodes.js'
import EditRouteModal from '../../components/routes/EditRouteModal.js'
import RoutePanel from '../../components/routes/RoutePanel.js'
import RouteListItem from '../../components/routes/RouteListItem.js'
import moment from 'moment'
import {formatDuration} from '../../../utils/utils.js'

export default class Planning extends Reflux.PureComponent {
    constructor(props) {
        super(props)

        this.stores = [OrderStore, UserStore]
    }

    async onClickMarker(ids) {
        const {viewOrder} = this.props
        const {orders} = this.state

        if (ids.length === 1) {
            viewOrder(ids[0])
        } else {
            const options = []

            for (const id of _.uniq(ids)) {
                let order = orders[id]

                if (!order) {
                    order = await new Promise((resolve, reject) => {
                        OrderActions.getOne(id, (err, order) => {
                            if (err) return reject(err)
                            resolve(order)
                        })
                    })
                }


                if (order) {
                    options.push(
                        <OrderCard
                            key={`OrderCard${id}`}
                            order={order}
                            onClick={() => {
                                this.popup.close()
                                viewOrder(id)
                            }}
                        />
                    )
                }
            }

            this.popup.openOptions('Rit keuze', 'Kies hieronder de rit die je wilt openen:', options)
        }
    }

    render() {
        const {users, usersObject, orderStartDate, orderEndDate, ordersLoading, filteredOrders, selectedOrders, routes, activeRouteTab, selectedRouteId, someRouteHidden, focusedRouteId, mapOptions, showRoutePanel, showOrderPanel, showOrdersOnMap, showCompletedAddressesOnMap, showRoutesOnMap, showStopNumbersOnMap, centerViewOnSelected} = this.state
        const {reseller, viewOrder} = this.props

        const notCompletedOrders = Object.values(filteredOrders).filter((order) => order.status === dbCodes.status.nietAfgerond())

        // TODO remove this from render to improve performance
        let nrOfRoutes = 0
        let nrOfAddresses = 0
        let nrOfAddressesWithoutPosition = 0
        let nrOfAddressesInRoute = 0
        let nrOfColli = 0
        let nrOfColliInRoute = 0
        let totalDistance = 0
        let totalDuration = 0
        const durations = []
        let lastEndTime = ''
        let totalSuccess = 0
        let totalNotCompleted = 0
        let totalTooLate = 0
        let totalDelay = 0

        Object.keys(routes).map((routeId) => {
            const route = routes[routeId]

            if (!route.completed && activeRouteTab !== 'completed') {
                nrOfRoutes += 1
                nrOfAddressesInRoute += route.addresses?.length || 0
                nrOfColliInRoute += route.colli?.length || 0
                totalDistance += route.distance || 0
                totalDuration += route.duration || 0
                durations.push(route.duration || 0)
            }

            if (route.completed && activeRouteTab === 'completed') {
                nrOfRoutes += 1
                totalSuccess += route.success || 0
                totalNotCompleted += route.notCompleted || 0
                totalTooLate += route.tooLate || 0

                if (route.endTime && route.startTime) {
                    const completedDuration = moment(route.endTime, 'HH:mm').diff(moment(route.startTime, 'HH:mm'), 'seconds')
                    totalDuration += completedDuration

                    if (route.plannedDuration) {
                        totalDelay += completedDuration - route.plannedDuration
                    }
                }
            }

            if (route.estimatedEndTime) {
                if (route.estimatedEndTime > lastEndTime) {
                    lastEndTime = route.estimatedEndTime
                }
            }
        })

        Object.keys(filteredOrders).map((orderId) => {
            const order = filteredOrders[orderId]

            if (!order.routeId) {
                nrOfAddresses += order.addresses.length
                nrOfColli += order.colli.length

                order.addresses.map((address) => {
                    if (!address.position) {
                        nrOfAddressesWithoutPosition += 1
                    }
                })
            }
        })

        return (
            <FlexRow style={{height: '100%'}}>
                <FlexRow style={{alignItems: 'center', height: '100%', width: 'fit-content'}}>
                    <FlexRow style={{alignItems: 'stretch', height: '100%', width: showRoutePanel && routes[selectedRouteId] ? 812 : showRoutePanel ? 400 : 0, overflow: 'hidden', transition: 'all 0.2s ease',
                        borderRight: `1px solid ${ Colors.grey40}`}}
                    >
                        <FlexColumn style={{width: 400, flexShrink: 0, alignItems: 'center'}}>

                            <H6 style={{marginTop: 12}}>Routes</H6>

                            <Tabs
                                style={{width: '100%', marginBottom: 0, paddingBottom: 2}}
                                value={activeRouteTab}
                                onChange={(event) => OrderActions.setActiveRouteTab(event.target.value)}
                            >
                                <Tab style={{width: '100%'}} value='open' noCaps>Open</Tab>
                                <Tab style={{width: '100%'}} value='completed' noCaps>Afgerond</Tab>
                            </Tabs>

                            {activeRouteTab === 'open' &&
                                <Button
                                    style={{marginTop: 6, marginLeft: 6, marginRight: 6, width: 388}}
                                    variant='outline-white'
                                    icon='mdi mdi-plus'
                                    label='Nieuwe route'
                                    onClick={() => this.editRouteModal.open()}
                                />
                            }

                            <div style={{flex: '1 0px', width: '100%', overflowY: 'auto', padding: 6}}>
                                {Object.keys(routes).sort().reverse().map((routeId) => {
                                    const route = routes[routeId]

                                    if (route.date === orderStartDate && (activeRouteTab === 'open' && !route.completed) || (activeRouteTab === 'completed' && route.completed)) {
                                        return (
                                            <RouteListItem
                                                key={routeId}
                                                users={users}
                                                route={route}
                                                onEditRoute={(route) => this.editRouteModal.open(route)}
                                                focusedRouteId={focusedRouteId}
                                                selectedRouteId={selectedRouteId}
                                            />
                                        )
                                    }
                                })}
                            </div>

                            <Panel style={{padding: 6, background: Colors.backgroundNeutral, borderLeft: 'none', borderRight: 'none'}}>
                                <FlexRow style={{justifyContent: 'center', marginBottom: 6}}>
                                    <S1>{`${nrOfRoutes} ${nrOfRoutes === 1 ? 'Route' : 'Routes'}`}</S1>
                                </FlexRow>

                                {activeRouteTab === 'open' &&
                                    <FlexRow style={{justifyContent: 'space-between', gap: 12}}>
                                        <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                            <ToolTip text='Aantal adressen' placement='top' tooltipStyle={{marginLeft: -36}}>
                                                <Icon style={{marginRight: 0}} icon='mdi mdi-home-outline'/>
                                            </ToolTip>
                                            <P>{nrOfAddressesInRoute}</P>
                                        </FlexColumn>

                                        <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                            <ToolTip text='Aantal colli' placement='top'>
                                                <Icon style={{marginRight: 0}} icon='mdi mdi-package-variant-closed'/>
                                            </ToolTip>

                                            <P>{nrOfColliInRoute}</P>
                                        </FlexColumn>

                                        <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                            <ToolTip text='Afstand' placement='top'>
                                                <Icon style={{marginRight: 0}} icon='mdi mdi-road-variant'/>
                                            </ToolTip>

                                            <P>
                                                {totalDistance < 10000 ?
                                                    `${(totalDistance / 1000).toFixed(1)} km` :
                                                    `${(totalDistance / 1000).toFixed(0)} km`
                                                }

                                            </P>
                                        </FlexColumn>

                                        <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                            <ToolTip text='Berekende duur' placement='top'>
                                                <Icon style={{marginRight: 0}} icon='mdi mdi-timer-sand'/>
                                            </ToolTip>

                                            <P>
                                                {
                                                    `${parseInt(totalDuration / 3600) < 10 ? '0' : ''}${parseInt(totalDuration / 3600)}:${parseInt((totalDuration % 3600) / 60) < 10 ? '0' : ''}${parseInt((totalDuration % 3600) / 60)}`
                                                }
                                            </P>
                                        </FlexColumn>

                                        <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                            <Icon style={{marginRight: 0}} icon='mdi mdi-clock-check-outline'/>

                                            <P>{lastEndTime}</P>
                                        </FlexColumn>
                                    </FlexRow>
                                }

                                {activeRouteTab === 'completed' &&
                                    <FlexRow style={{justifyContent: 'space-between', gap: 12}}>
                                        <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                            <ToolTip text='Afgerond' placement='top' tooltipStyle={{marginLeft: -30}}>
                                                <Icon style={{marginRight: 0}} icon='mdi mdi-check-bold'/>
                                            </ToolTip>

                                            <P>{totalSuccess || 0}</P>
                                        </FlexColumn>

                                        <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                            <ToolTip text='Niet afgerond' placement='top'>
                                                <Icon style={{marginRight: 0}} icon='mdi mdi-close-thick'/>
                                            </ToolTip>

                                            <P>{totalNotCompleted || 0}</P>
                                        </FlexColumn>

                                        <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                            <ToolTip text='Te laat' placement='top'>
                                                <Icon style={{marginRight: 0}} icon='mdi mdi-clock-remove-outline'/>
                                            </ToolTip>

                                            <P>{totalTooLate || 0}</P>

                                        </FlexColumn>

                                        <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                            <ToolTip text='Berekende duur' placement='top'>
                                                <Icon
                                                    style={{marginRight: 0}}
                                                    icon={'mdi mdi-timer-sand-complete'}
                                                />
                                            </ToolTip>

                                            <P>{formatDuration(totalDuration)}</P>
                                        </FlexColumn>

                                        <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                            <ToolTip text='Afwijking ETA' placement='top'>
                                                <Icon style={{marginRight: 0}} icon='mdi mdi-flag-checkered'/>
                                            </ToolTip>

                                            <P>{formatDuration(totalDelay, true)}</P>
                                        </FlexColumn>
                                    </FlexRow>
                                }
                            </Panel>
                        </FlexColumn>

                        <RoutePanel
                            usersObject={usersObject}
                            route={routes[selectedRouteId]}
                            focusedRouteId={focusedRouteId}
                            viewOrder={viewOrder}
                            onEditRoute={(route) => this.editRouteModal.open(route)}
                            onClose={() => {
                                if (focusedRouteId) {
                                    OrderActions.setRouteFocus('')
                                }

                                OrderActions.setSelectedRoute('')
                            }}
                            ref={(ref) => this.routePanel = ref}
                        />
                    </FlexRow>

                    <div style={{position: 'relative', width: 0, zIndex: 999}}>
                        <Button
                            variant='outline-white'
                            style={{position: 'absolute', left: -6, height: 36}}
                            buttonStyle={{paddingLeft: 3, paddingRight: 3, borderRadius: '0px 4px 4px 0px'}}
                            onClick={OrderActions.setShowRoutePanel}
                        >
                            {showRoutePanel ?
                                <i className='mdi mdi-chevron-left'/> :
                                <i className='mdi mdi-chevron-right'/>
                            }
                        </Button>
                    </div>
                </FlexRow>

                <FlexColumn>
                    {orderStartDate !== orderEndDate &&
                        <Alert variant='info' style={{marginBottom: 0, marginLeft: 0}}>{`Op de kaart worden alleen ritten van ${moment(orderStartDate).format('DD-MM-YYYY')} weergegeven`}</Alert>
                    }

                    <Map
                        users={users}
                        reseller={reseller}
                        loading={ordersLoading}
                        date={orderStartDate}
                        orders={filteredOrders}
                        selectedOrders={selectedOrders}
                        options={mapOptions}
                        showRoutePanel={showRoutePanel}
                        showOrderPanel={showOrderPanel}
                        showOrdersOnMap={showOrdersOnMap}
                        showCompletedAddressesOnMap={showCompletedAddressesOnMap}
                        showRoutesOnMap={showRoutesOnMap}
                        showStopNumbersOnMap={showStopNumbersOnMap}
                        selectedRouteId={selectedRouteId}
                        focusedRouteId={focusedRouteId}
                        someRouteHidden={someRouteHidden}
                        activeRouteTab={activeRouteTab}
                        routes={routes}
                        onClick={this.onClickMarker.bind(this)}
                        onChange={OrderActions.setSelected}
                        centerViewOnSelected={centerViewOnSelected}
                    />
                </FlexColumn>

                <FlexRow style={{alignItems: 'center', height: '100%', width: 'fit-content'}}>
                    <div style={{position: 'relative', width: 0, zIndex: 999}}>
                        <Button
                            variant='outline-white'
                            style={{position: 'absolute', right: -6, height: 36}}
                            buttonStyle={{paddingLeft: 3, paddingRight: 3, borderRadius: '4px 0px 0px 4px'}}
                            onClick={OrderActions.setShowOrderPanel}
                        >
                            {showOrderPanel ?
                                <i className='mdi mdi-chevron-right'/> :
                                <i className='mdi mdi-chevron-left'/>
                            }
                        </Button>
                    </div>

                    <FlexColumn style={{width: showOrderPanel ? 350 : 0, overflow: 'hidden', alignItems: 'center', transition: 'all 0.2s ease', borderLeft: `1px solid ${ Colors.grey40}`}}>
                        <H6 style={{marginTop: 12}}>Ritten</H6>

                        <OrderList
                            date={orderStartDate}
                            filteredOrders={filteredOrders}
                            viewOrder={viewOrder}
                        />

                        <Panel style={{padding: 6, background: Colors.backgroundNeutral, borderLeft: 'none', borderRight: 'none'}}>
                            <FlexRow style={{justifyContent: 'space-between', gap: 12}}>
                                <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                    <ToolTip tooltipStyle={{marginLeft: -30}} text='Niet afgerond' placement='top'>
                                        <Icon icon='mdi mdi-circle' style={{color: Colors.warningBright, marginLeft: -2, marginRight: 0}}/>
                                    </ToolTip>

                                    <P>{notCompletedOrders.length}</P>
                                </FlexColumn>

                                <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                    <ToolTip text='Foutieve adressen' placement='top'>
                                        <Icon style={{marginRight: 0}} icon='mdi mdi-alert'/>
                                    </ToolTip>

                                    <P>{nrOfAddressesWithoutPosition}</P>
                                </FlexColumn>

                                <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                    <ToolTip text='Aantal adressen' placement='top'>
                                        <Icon style={{marginRight: 0}} icon='mdi mdi-home-outline'/>
                                    </ToolTip>
                                    <P>{nrOfAddresses}</P>
                                </FlexColumn>

                                <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                    <ToolTip text='Aantal colli' placement='top'>
                                        <Icon style={{marginRight: 0}} icon='mdi mdi-package-variant-closed'/>
                                    </ToolTip>
                                    <P>{nrOfColli}</P>
                                </FlexColumn>

                                <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                    <ToolTip tooltipStyle={{marginLeft: -132}} text='Aantal geselecteerd' placement='top'>
                                        <Icon style={{marginRight: 0}} icon='mdi mdi-select'/>
                                    </ToolTip>

                                    <P>{selectedOrders.length}</P>
                                </FlexColumn>

                            </FlexRow>
                        </Panel>
                    </FlexColumn>
                </FlexRow>

                <Popup closeButton ref={(ref) => this.popup = ref}/>
                <EditRouteModal reseller={reseller} users={usersObject} ref={(ref) => this.editRouteModal = ref}/>
            </FlexRow>
        )
    }
}

