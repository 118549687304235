import time from '../../../server/utils/time.js'
import Colors from '../UI/Colors.js'


export default
/**
 *
 * @param {Reseller} reseller
 *
 * @returns {Route}
 */
(reseller) => {
    const route = {
        date: time.today(),
        messengerId: '',
        color: Colors.routeColors[Math.floor(Math.random() * Colors.routeColors.length)],
        name: '',
        plannedStartTime: '',
        startPoint: reseller?.settings ? {...reseller.settings.companyAddress} : {name: '', street: '', nr: '', addition: '', postalCode: '', city: ''},
        endPoint: reseller?.settings ? {...reseller.settings.companyAddress} : {name: '', street: '', nr: '', addition: '', postalCode: '', city: ''},
        vehicleType: reseller?.settings.defaultVehicle || 'bike',
        parseInt: reseller?.settings.parseInt || 0,
        stopTime: reseller?.settings.stopTime || 180,
        instructions: '',
        optimizeForTimeslots: !!reseller?.settings.optimizeForTimeslots,
        adjustableByMessenger: !!reseller?.settings.adjustableByMessenger,
        completedAddresses: [],
        completedColli: [],
        orderIds: []

    }


    return JSON.parse(JSON.stringify(route))
}
