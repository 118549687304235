import React from 'react'
import Colors from './Colors.js'


export default class Icon extends React.Component {
    render() {
        const {icon, size, style={}} = this.props

        return (
            <i
                style={{
                    fontSize: size === 'sm' ? 14 : size === 'md' ? 16 :18,
                    marginRight: 6,
                    color: Colors.textDark,
                    ...style
                }}

                className={icon}
            />
        )
    }
}
