import Reflux from 'reflux-react-16'

export default Reflux.createActions([
    'getAccounts',
    'login',
    'switchAccount',
    'loginAsReseller',
    'loginAsCustomer',
    'logout',
    'loggedIn',

    'getAccount',
    'updateUserSettings',
    'getShippingMethods',
    'installLightspeed',
    'uninstallLightspeed',
    'installShopify',
    'installExactOnline',
    'verifyWebshop',
    'installMollie',
    'getMollieProfiles',

    'getReseller',
    'updateReseller',
    'updateResellerSettings',

    'sendResetPasswordMail',
    'resetPassword',
    'sendCustomerWelcomeMail',
    'sendUserWelcomeMail',
    'generateMFAKey',
    'mailMFACode',
    'reset2FA',
    'verify2FACode',

    'checkNotifications',
    'updateReadNotifications',

    'getBranding',

    'verifyCycloonConnection'

])
