import React from 'react'
import {Colors, P} from '../UI/index.js'
import {Check} from '../UI/index.js'


export default class Row extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            columnHover: {}
        }
    }

    onMouseEnter(index) {
        const columnHover = {...this.state.columnHover}
        columnHover[index] = true
        this.setState({columnHover})
    }

    onMouseLeave(index) {
        const columnHover = {...this.state.columnHover}
        columnHover[index] = false
        this.setState({columnHover})
    }

    renderRowItems() {
        const {row, columns, selectRow} = this.props

        return columns.map((column, index) => {
            if (column.visible && !column.filterOnly) {
                const style = {display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: 12, marginRight: 12, minHeight: 50}

                if (column.width) {
                    style.width = column.width
                }

                if (column.flex) {
                    style.flex = column.flex
                }

                if (column.onClick) {
                    style.textDecoration = this.state.columnHover[index] ? 'underline' : 'none'
                }

                let value
                if (column.render) {
                    value = column.render(row)
                } else if (column.text) {
                    value = column.text(row)
                } else {
                    value = traverse(row, column.key)
                }

                const onClick = column.onClick ? column.onClick.bind(this, row) : selectRow.bind(this, row._id)

                return (
                    <P
                        key={`rowItem${index}`}
                        ellipsis
                        style={style}
                        onClick={onClick || null}
                        onMouseEnter={this.onMouseEnter.bind(this, index)}
                        onMouseLeave={this.onMouseLeave.bind(this, index)}
                    >
                        {value}
                    </P>
                )
            }
        })
    }

    render() {
        const {row, selected, selectRow, background, canCheck} = this.props

        const style = {
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
            height: 50,
            background: this.state.hover ? 'rgba(0, 0, 0, 0.02)' : 'transparent',
            borderBottom: 'solid 1px',
            borderColor: Colors.grey20
        }

        return (
            <div
                style={{width: '100%', background: background || Colors.backgroundWhite}}
                onMouseEnter={() => this.setState({hover: true})}
                onMouseLeave={() => this.setState({hover: false})}
            >
                <div style={style}>
                    {canCheck &&
                        <>
                            <Check
                                checked={selected}
                                onChange={selectRow.bind(this, row._id)}
                            />

                            <div style={{width: 20}}/>
                        </>
                    }

                    {this.renderRowItems()}
                </div>
            </div>
        )
    }
}

/**
* Traverse an object the find the value for the given multilevel key
* @param {object} obj
* @param {string} key multilevel key seperated by dots
* @return {string}
*/
function traverse(obj, key) {
    if (key) {
        return key.split('.').reduce((cur, key) => {
            if (cur && cur[key]) {
                return cur[key]
            } else {
                return ''
            }
        }, obj)
    } else {
        return ''
    }
}

