import React, {useRef, useEffect, useState} from 'react'
import moment from 'moment'

import OrderActions from '../../actions/OrderActions.js'

import {ColorPicker, FlexRow, FlexColumn, Card, Select, Icon, S1, Badge, ToolTip} from '../../components/UI/index.js'
import {Popover, ListItem, IconButton, Colors, P, Popup, Spinner} from '../../components/UI/index.js'
import time from '../../../server/utils/time.js'
import {formatDuration} from '../../../utils/utils.js'


export default React.memo(function RouteListItem({users, route, selectedRouteId, focusedRouteId, onEditRoute}) {
    const popupRef = useRef()
    const titleRef = useRef()
    const [titleIsEllipsis, setTitleIsEllipsis] = useState(false)
    const selected = selectedRouteId === route._id


    route.afterTimeSlot = route.addresses?.some((address) =>{
        let afterTimeSlot = false

        if (address.estimatedTimeOfArrival) {
            afterTimeSlot = moment(address.estimatedTimeOfArrival, 'HH:mm').isAfter(moment(address.endTime, 'HH:mm'))
        } else if (address.duration) {
            afterTimeSlot = moment().add(address.duration || 0, 'seconds').isAfter(moment(address.endTime, 'HH:mm'))
        }

        if (address.timeOfArrival) {
            afterTimeSlot = moment(address.timeOfArrival, 'HH:mm').isAfter(moment(address.endTime, 'HH:mm'))
        }

        return afterTimeSlot
    })

    route.beforeTimeSlot = !route.afterTimeSlot && route.addresses?.some((address) =>{
        let beforeTimeSlot = false

        if (address.estimatedTimeOfArrival) {
            beforeTimeSlot = moment(address.estimatedTimeOfArrival, 'HH:mm').isBefore(moment(address.startTime, 'HH:mm'))
        } else if (address.duration) {
            beforeTimeSlot = moment().add(address.duration || 0, 'seconds').isBefore(moment(address.startTime, 'HH:mm'))
        }

        if (address.timeOfArrival) {
            beforeTimeSlot = moment(address.timeOfArrival, 'HH:mm').isBefore(moment(address.startTime, 'HH:mm'))
        }

        return beforeTimeSlot
    })

    route.inTimeSlots = !route.afterTimeSlot && !route.beforeTimeSlot

    let completedDuration = route.duration || 0
    let completedDelay = route.delay || 0

    if (route.completed && route.endTime && route.startTime) {
        completedDuration = moment(route.endTime, 'HH:mm').diff(moment(route.startTime, 'HH:mm'), 'seconds')
        completedDelay = completedDuration - route.plannedDuration
    }

    useEffect(() => {
        const el = titleRef?.current?.ref
        if (el) {
            if (el.offsetWidth < el.scrollWidth) {
                setTitleIsEllipsis(true)
            }
        }
    }, [titleRef.current])

    return (
        <Card key={route._id} shadow='true' selected={selected} style={{padding: 0, marginBottom: 6}}>
            <FlexRow style={{height: '100%', alignItems: 'stretch'}}>
                <FlexColumn style={{padding: 6}}>
                    <FlexRow style={{alignItems: 'center'}}>
                        {route.completed ?
                            <Icon
                                style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 36, height: 36, fontSize: 20, color: route.color}}
                                icon='mdi mdi-circle'
                            /> :
                            <ColorPicker
                                value={route.color}
                                colors={Colors.routeColors}
                                onChange={(event) => OrderActions.updateRoute(route._id, {color: event.target.value})}
                            />
                        }

                        {titleIsEllipsis ? (
                            <ToolTip placement='right' tooltipStyle={{width: 'fit-content', whiteSpace: 'nowrap', padding: '0 8px', marginLeft: -70, maxWidth: 350}} text={route.name}>
                                <S1 ref={titleRef} ellipsis style={{flex: 2, cursor: 'pointer', maxWidth: 130}} onClick={() => OrderActions.setSelectedRoute(route._id)}>{route.name}</S1>
                            </ToolTip>
                        ) : (
                            <S1 ref={titleRef} ellipsis style={{flex: 2, cursor: 'pointer', maxWidth: 130}} onClick={() => OrderActions.setSelectedRoute(route._id)}>{route.name}</S1>
                        )}


                        <FlexColumn style={{alignItems: 'center', cursor: 'pointer'}} onClick={() => OrderActions.setSelectedRoute(route._id)}>
                            <FlexRow style={{alignItems: 'center', justifyContent: 'flex-end'}}>

                                <FlexColumn>
                                    {!route.ready && !route.started && !route.completed &&
                                        <Badge variant='grey'>Concept</Badge>
                                    }

                                    {route.ready && !route.started && !route.completed &&
                                        <Badge variant='white'>Gereed</Badge>
                                    }

                                    {route.started && !route.completed &&
                                        <Badge textColor={Colors.textDark} variant='info'>Onderweg</Badge>
                                    }

                                    {route.completed &&
                                        <Badge variant='success'>Afgerond</Badge>
                                    }
                                </FlexColumn>

                                <FlexColumn style={{marginLeft: 12, width: 50}}>
                                    <P>{route.plannedStartTime || route.startTime}</P>
                                </FlexColumn>
                            </FlexRow>
                        </FlexColumn>

                        {!route.completed &&
                            <Popover
                                placement='left'
                                content={
                                    <div style={{width: 'fit-content'}}>
                                        <ListItem
                                            size='sm'
                                            icon={focusedRouteId === route?._id ? 'mdi mdi-image-filter-center-focus-weak' : 'mdi mdi-image-filter-center-focus-strong'}
                                            onClick={() => OrderActions.setRouteFocus(route?._id)}
                                        >

                                            {route._id === focusedRouteId ? 'Reset focus' : 'Focus route'}
                                        </ListItem>

                                        <ListItem
                                            size='sm'
                                            icon={route.hidden ?
                                                'mdi mdi-map-marker':
                                                'mdi mdi-map-marker-off'
                                            }
                                            onClick={() => OrderActions.setRouteHidden(route._id)}
                                        >

                                            {route.hidden ? 'Toon route' : 'Verberg route'}
                                        </ListItem>

                                        <ListItem
                                            size='sm'
                                            icon='mdi mdi-tune'
                                            onClick={() => onEditRoute(route)}
                                        >
                                            Route instellingen
                                        </ListItem>

                                        <ListItem
                                            size='sm'
                                            icon='mdi mdi-map-marker-path'
                                            disabled={route.ready}
                                            onClick={() => OrderActions.planRoute(route._id, true)}
                                        >
                                            Optimaliseer route
                                        </ListItem>

                                        <ListItem
                                            size='sm'
                                            icon='mdi mdi-swap-vertical'
                                            disabled={route.ready || route.started || !route.reversible}
                                            onClick={() => OrderActions.planRoute(route._id, false, true)}
                                        >
                                            Route omdraaien
                                        </ListItem>

                                        <ListItem
                                            size='sm'
                                            icon={route.ready ? 'mdi mdi-pencil' : 'mdi mdi-check'}
                                            onClick={async () => {
                                                if (route.ready) {
                                                    await popupRef.current.open('Route wijzigen', 'Weet je zeker dat je deze route wilt wijzigen? ')
                                                }

                                                OrderActions.setRouteReady(route._id, !route.ready, (err) => {
                                                    if (err) {
                                                        popupRef.current.setError(err)
                                                    } else {
                                                        popupRef.current.close()
                                                    }
                                                })
                                            }}
                                        >
                                            {route.ready ? 'Route wijzigen' : 'Route gereed'}
                                        </ListItem>

                                        <ListItem
                                            size='sm'
                                            icon={'mdi mdi-calendar'}
                                            onClick={async () => {
                                                popupRef.current.setInput({type: 'date', value: time.tomorrow(), label: 'Datum'})
                                                const date = await popupRef.current.open('Route verplaatsen', 'Weet je zeker dit zeker?')

                                                OrderActions.rescheduleRoute(route._id, date, (err) => {
                                                    if (err) {
                                                        popupRef.current.setError(err)
                                                    } else {
                                                        popupRef.current.close()
                                                    }
                                                })
                                            }}
                                        >

                                            Route verplaatsen
                                        </ListItem>


                                        <ListItem
                                            style={{color: route?.ready || route?.started || route?.completed ? undefined : Colors.errorBright}}
                                            size='sm'
                                            icon='mdi mdi-delete'
                                            disabled={route?.ready || route?.started || route?.completed}
                                            onClick={async () => {
                                                if (route.addresses?.length) {
                                                    await popupRef.current.open('Route verwijderen', 'Deze route bevat stops, weet je zeker dat je deze route wilt verwijderen?')
                                                }

                                                OrderActions.removeRoute(route._id, (err) => {
                                                    if (err) {
                                                        popupRef.current.setError(err)
                                                    } else {
                                                        popupRef.current.close()
                                                    }
                                                })
                                            }}
                                        >
                                            Route verwijderen
                                        </ListItem>
                                    </div>
                                }
                            >
                                <IconButton icon='mdi mdi-dots-vertical'/>
                            </Popover>
                        }
                    </FlexRow>

                    <FlexRow>
                        <FlexColumn style={{paddingLeft: 6, paddingRight: 6}}>
                            <Select
                                style={{width: '100%'}}
                                label='Koerier'
                                allowEmptyValue
                                options={users.map((user) => {
                                    return {value: user._id, title: user.name}
                                })}
                                value={route.messengerId}
                                readOnly={route.completed}
                                onChange={(event) => OrderActions.updateRoute(route._id, {messengerId: event.target.value})}
                            />
                        </FlexColumn>

                        <FlexColumn style={{paddingLeft: 6, paddingRight: 6}}>
                            <Select
                                style={{width: '100%'}}
                                label='Voertuig'
                                options={[
                                    {value: 'bike', title: 'Fiets'},
                                    {value: 'car', title: 'Auto'},
                                    {value: 'walk', title: 'Lopen'}
                                ]}
                                value={route.vehicleType}
                                readOnly={route.completed}
                                onChange={(event) => OrderActions.updateRoute(route._id, {vehicleType: event.target.value})}
                            />
                        </FlexColumn>
                    </FlexRow>


                    {route.loading &&
                        <FlexRow style={{justifyContent: 'center', alignItems: 'center', height: 42}}>
                            <Spinner name='ball-clip-rotate' fadeIn='none'/>
                        </FlexRow>
                    }

                    {!route.loading && !route.completed &&
                        <FlexRow style={{justifyContent: 'space-between', gap: 12, cursor: 'pointer'}} onClick={() => OrderActions.setSelectedRoute(route._id)}>

                            <ToolTip text='Aantal adressen' placement='top' style={{flex: 1}} tooltipStyle={{marginLeft: -36}}>
                                <FlexColumn style={{alignItems: 'center'}}>
                                    <Icon style={{marginRight: 0}} icon='mdi mdi-home-outline'/>

                                    <P>{route.addresses?.length || 0}</P>
                                </FlexColumn>
                            </ToolTip>

                            <ToolTip text='Aantal colli' placement='top' style={{flex: 1}}>
                                <FlexColumn style={{alignItems: 'center'}}>
                                    <Icon style={{marginRight: 0}} icon='mdi mdi-package-variant-closed'/>

                                    <P>{route.colli?.filter((c, index, self) => c.barcode ? index === self.findIndex((collo) => collo.barcode === c.barcode) : true).length || 0}</P>
                                </FlexColumn>
                            </ToolTip>

                            <ToolTip text='Resterende afstand' placement='top' style={{flex: 1}}>
                                <FlexColumn style={{alignItems: 'center'}}>
                                    <Icon style={{marginRight: 0}} icon='mdi mdi-road-variant'/>

                                    {typeof route.distance === 'number' &&
                                        <P>
                                            {route.distance < 10000 ?
                                                `${(route.distance / 1000).toFixed(1)} km` :
                                                `${(route.distance / 1000).toFixed(0)} km`
                                            }
                                        </P>
                                    }
                                </FlexColumn>
                            </ToolTip>

                            <ToolTip text='Resterende duur' placement='top' style={{flex: 1}}>
                                <FlexColumn style={{alignItems: 'center'}}>
                                    <Icon
                                        style={{marginRight: 0, color: !route.startTime && route.afterTimeSlot ? Colors.errorBright :undefined}}
                                        icon={!route.startTime && !route.inTimeSlots ? 'mdi mdi-timer-sand-complete' : 'mdi mdi-timer-sand'}
                                    />

                                    {typeof route.duration === 'number' &&
                                        <P>{formatDuration(route.duration)}</P>
                                    }
                                </FlexColumn>
                            </ToolTip>

                            <ToolTip text='Verwachte eindtijd' placement='top' style={{flex: 1}} tooltipStyle={{marginLeft: -100}}>
                                <FlexColumn style={{alignItems: 'center'}}>
                                    <Icon
                                        style={{marginRight: 0, color: (route.plannedStartTime || route.startTime) && !route.inTimeSlots ? (route.afterTimeSlot ? Colors.errorBright : Colors.warningBright) : undefined}}
                                        icon={(route.plannedStartTime || route.startTime) && !route.inTimeSlots ? 'mdi mdi-clock-alert-outline' : 'mdi mdi-clock-check-outline'}
                                    />

                                    {route.estimatedEndTime &&
                                        <P>{route.estimatedEndTime}</P>
                                    }
                                </FlexColumn>
                            </ToolTip>
                        </FlexRow>
                    }

                    {route.completed &&
                        <FlexRow style={{justifyContent: 'space-between', gap: 12, cursor: 'pointer'}} onClick={() => OrderActions.setSelectedRoute(route._id)}>
                            <ToolTip text='Afgerond' placement='top' style={{flex: 1}} tooltipStyle={{marginLeft: -30}}>
                                <FlexColumn style={{alignItems: 'center'}}>
                                    <Icon style={{marginRight: 0}} icon='mdi mdi-check-bold'/>

                                    <P>{route.success || 0}</P>
                                </FlexColumn>
                            </ToolTip>

                            <ToolTip text='Niet afgerond' style={{flex: 1}} placement='top'>
                                <FlexColumn style={{flex: 1, alignItems: 'center'}}>
                                    <Icon style={{marginRight: 0}} icon='mdi mdi-close-thick'/>

                                    <P>{route.notCompleted || 0}</P>
                                </FlexColumn>
                            </ToolTip>

                            <ToolTip text='Te laat' placement='top' style={{flex: 1}}>
                                <FlexColumn style={{alignItems: 'center'}}>
                                    <Icon style={{marginRight: 0}} icon='mdi mdi-clock-remove-outline'/>

                                    <P>{route.tooLate || 0}</P>
                                </FlexColumn>
                            </ToolTip>

                            <ToolTip text='Duur' placement='top' style={{flex: 1}}>
                                <FlexColumn style={{alignItems: 'center'}}>
                                    <Icon
                                        style={{marginRight: 0}}
                                        icon={'mdi mdi-timer-sand-complete'}
                                    />

                                    {typeof completedDuration === 'number' &&
                                        <P>{formatDuration(completedDuration)}</P>
                                    }

                                </FlexColumn>
                            </ToolTip>

                            <ToolTip text='Afwijking berekende duur' placement='top' style={{flex: 1}} tooltipStyle={{marginLeft: -150}}>
                                <FlexColumn style={{alignItems: 'center'}}>
                                    <Icon icon='mdi mdi-flag-checkered'/>

                                    {typeof completedDelay === 'number' &&
                                        <P>{formatDuration(completedDelay, true)}</P>
                                    }
                                </FlexColumn>
                            </ToolTip>
                        </FlexRow>
                    }
                </FlexColumn>

                <FlexRow
                    style={{
                        width: 24,
                        backgroundColor: Colors.backgroundSelected,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={() => OrderActions.setSelectedRoute(selected ? '' : route._id)}
                >
                    <Icon icon={selected ? 'mdi mdi-chevron-left' : 'mdi mdi-chevron-right'}/>
                </FlexRow>
            </FlexRow>

            <Popup ref={popupRef}/>
        </Card>
    )
})

