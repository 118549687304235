import React from 'react'

import {Colors, ToolTip, Spinner} from '../UI/index.js'


export default class IconButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hover: false
        }
    }

    onClick(event) {
        typeof this.props.onClick === 'function' && this.props.onClick(event)
    }

    render() {
        const {icon, children, style = {}, type = 'button', disabled, noHover, loading, onMouseDown, tooltip, label, badge} = this.props


        if (label) {
            const defaultStyle = {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexShrink: 0,
                width: 'fit-content',
                height: 48,
                textAlign: 'center',
                textDecoration: 'none',
                fontWeight: 500,
                letterSpacing: 0.1,
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 6,
                paddingRight: 6,
                color: disabled ? Colors.grey40 : Colors.buttonSolid,
                border: 'none',
                borderRadius: 4,
                outline: 0,
                cursor: disabled ? 'default' : 'pointer',
                transition: '0.1s all',
                ...style,
                background: !disabled && (this.state.hover || this.state.focus) ? 'rgba(0, 0 ,0 , 0.05)' : (style?.background || 'transparent')
            }

            return (
                <button
                    tabIndex="-1"
                    style={defaultStyle}
                    type={type}
                    disabled={disabled}
                    onClick={loading ? null : this.onClick.bind(this)}
                    onMouseDown={loading ? null : onMouseDown}
                    onMouseEnter={() => this.setState({hover: !noHover && true})}
                    onMouseLeave={() => this.setState({hover: false})}
                >
                    {loading ?
                        <Spinner name='ball-clip-rotate' fadeIn='none'/> :
                        <>
                            <i className={icon} style={{fontSize: 18}}/>
                            <div style={{fontSize: 14}}>{label}</div>
                        </>
                    }
                </button>
            )
        } else {
            const defaultStyle = {
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
                width: 36,
                height: 36,
                textAlign: 'center',
                textDecoration: 'none',
                fontSize: 20,
                color: disabled ? Colors.grey40 : Colors.buttonSolid,
                border: 'none',
                borderRadius: '50%',
                outline: 0,
                cursor: 'pointer',
                transition: '0.1s all',
                ...style,
                background: !disabled && (this.state.hover || this.state.focus) ? 'rgba(0, 0 ,0 , 0.05)' : (style?.background || 'transparent')

            }

            return (
                <ToolTip disabled={disabled} text={tooltip}>
                    <button
                        tabIndex="-1"
                        style={defaultStyle}
                        type={type}
                        disabled={disabled}
                        onClick={loading ? null : this.onClick.bind(this)}
                        onMouseDown={loading ? null : onMouseDown}
                        onMouseEnter={() => this.setState({hover: !noHover && true})}
                        onMouseLeave={() => this.setState({hover: false})}
                    >
                        {loading ?
                            <Spinner name='ball-clip-rotate' fadeIn='none'/> :
                            icon ?
                                <i className={icon}/> :
                                children
                        }
                    </button>

                    {!!badge &&
                        <i style={{position: 'absolute', top: -8, left: 22, color: Colors.errorBright, fontSize: 18}} className={`mdi mdi-numeric-${badge}-circle`}/>
                    }
                </ToolTip>
            )
        }
    }
}
